<template>
  <span>
    <v-row dense justify="center" class="mt-5">
      <v-col
        v-for="indicador in indicadores"
        :key="indicador"
        cols="5"
        sm="3"
        md="auto"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-title>
            <v-tooltip
              v-if="traducaoIndicadores[indicador] != undefined"
              bottom
            >
              <template v-slot:activator="{ on }">
                <h5 v-on="on">
                  {{ indicador.replaceAll("_", " ") | toUpperCase }}
                </h5>
              </template>
              <span>{{ traducaoIndicadores[indicador] }}</span>
            </v-tooltip>
            <h5 v-else>
              {{ indicador.replaceAll("_", " ") | toUpperCase }}
            </h5>
          </v-card-title>
          <v-card-text>
            <div class="display-3">
              {{
                dadosTotais[indicador] || "-" | parseNumberToFloatBrIgnoringNaN
              }}
              <span v-if="['prp', 'pri'].includes(indicador)">%</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center" class="mt-5">
      <v-col
        v-for="indicador in indicadores"
        :key="indicador"
        cols="12"
        md="6"
      >
        <v-card class="my-1">
          <v-card-title>
            <h5>{{ traducaoIndicadores[indicador] || indicador.toUpperCase() }}</h5>
          </v-card-title>
          <v-card-text>
            <column-chart-mensal
              :dadosMensais="dadosMensais"
              :categories="categories"
              :indicador="indicador"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
  export default {
    name: "LayoutVisaoGlobal",
    components: {
      ColumnChartMensal: () =>
        import(
          "@/components/dashboardNivelTensao/charts/ColumnChartMensal.vue"
        ),
    },
    data: () => ({
      //
    }),
    props: {
      indicadores: {
        type: Array,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
      dadosTotais: {
        type: Object,
        required: true,
      },
      dadosMensais: {
        type: Object,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: () => {},
      },
    },
  };
</script>
